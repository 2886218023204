var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observerRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('div',{staticClass:"flex gap-5 items-center mb-5"},[_c('label',{staticClass:"cursor-pointer"},[_c('TRadioUnwrapped',{attrs:{"value":false,"name":"chooseLock"},model:{value:(_vm.chooseLock),callback:function ($$v) {_vm.chooseLock=$$v},expression:"chooseLock"}}),_c('span',{staticClass:"ml-3 font-medium text-14px text-oDark"},[_vm._v(" "+_vm._s(_vm.isEditing ? _vm.$t( 'components.vehicleManagement.addEdit.steps.iot.title.updateCurrentIoT' ) : _vm.$t( 'components.vehicleManagement.addEdit.steps.iot.title.addNewIoT' ))+" ")])],1),_c('label',{staticClass:"cursor-pointer"},[_c('TRadioUnwrapped',{attrs:{"value":true,"name":"chooseLock"},model:{value:(_vm.chooseLock),callback:function ($$v) {_vm.chooseLock=$$v},expression:"chooseLock"}}),_c('span',{staticClass:"ml-3 font-medium text-14px text-oDark"},[_vm._v(" "+_vm._s(_vm.isEditing ? _vm.$t( 'components.vehicleManagement.addEdit.steps.iot.title.replaceCurrentIoT' ) : _vm.$t( 'components.vehicleManagement.addEdit.steps.iot.title.useExistingIoT' ))+" ")])],1)]),(_vm.chooseLock)?[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t('components.vehicleManagement.addEdit.steps.iot.title.chooseIoT'),"label":_vm.$t('components.vehicleManagement.addEdit.steps.iot.title.chooseIoT'),"placeholder":_vm.$t(
            'components.vehicleManagement.addEdit.steps.iot.placeHolder.chooseIoT'
          ),"rules":"required","value-attribute":"id","text-attribute":"name","options":_vm.unassignedLocks},model:{value:(_vm.selectedLock),callback:function ($$v) {_vm.selectedLock=$$v},expression:"selectedLock"}}),(_vm.selectedLockDetails)?[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t('components.vehicleManagement.addEdit.steps.iot.title.name'),"label":_vm.$t('components.vehicleManagement.addEdit.steps.iot.title.name'),"placeholder":"--","rules":"required","disabled":""},model:{value:(_vm.selectedLockDetails.name),callback:function ($$v) {_vm.$set(_vm.selectedLockDetails, "name", $$v)},expression:"selectedLockDetails.name"}}),_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.ioTType'
              ),"label":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.ioTType'
              ),"rules":"required","placeholder":"--","disabled":""},model:{value:(_vm.selectedLockDetails.iot_category),callback:function ($$v) {_vm.$set(_vm.selectedLockDetails, "iot_category", $$v)},expression:"selectedLockDetails.iot_category"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.deviceIMEI'
              ),"label":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.deviceIMEI'
              ),"placeholder":"--","disabled":""},model:{value:(_vm.selectedLockDetails.lock_id),callback:function ($$v) {_vm.$set(_vm.selectedLockDetails, "lock_id", $$v)},expression:"selectedLockDetails.lock_id"}}),_c('AppInput',{attrs:{"type":"text","name":"ID","label":"ID","placeholder":"--","disabled":""},model:{value:(_vm.selectedLockDetails.id),callback:function ($$v) {_vm.$set(_vm.selectedLockDetails, "id", $$v)},expression:"selectedLockDetails.id"}})],1)]:_vm._e()]:[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t('components.vehicleManagement.addEdit.steps.iot.title.ioTType'),"label":_vm.$t('components.vehicleManagement.addEdit.steps.iot.title.ioTType'),"placeholder":_vm.$t(
            'components.vehicleManagement.addEdit.steps.iot.placeHolder.ioTType'
          ),"rules":"required","value-attribute":"value","text-attribute":"display_name","options":_vm.lockTypes,"hide-search-box":""},model:{value:(_vm.form.iot_category),callback:function ($$v) {_vm.$set(_vm.form, "iot_category", $$v)},expression:"form.iot_category"}}),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t('components.vehicleManagement.addEdit.steps.iot.title.name'),"label":_vm.$t('components.vehicleManagement.addEdit.steps.iot.title.name'),"placeholder":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.placeHolder.name'
            ),"rules":"required"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.title.manufacturer'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.title.manufacturer'
            ),"placeholder":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.placeHolder.manufacturer'
            ),"value-attribute":"id","text-attribute":"name","options":_vm.lockManufacturers,"hide-search-box":""},model:{value:(_vm.form.manufacturer),callback:function ($$v) {_vm.$set(_vm.form, "manufacturer", $$v)},expression:"form.manufacturer"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.title.deviceIMEI'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.title.deviceIMEI'
            ),"rules":"required","placeholder":"e.g. 987654321"},model:{value:(_vm.form.lock_id),callback:function ($$v) {_vm.$set(_vm.form, "lock_id", $$v)},expression:"form.lock_id"}}),_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.title.factoryQRCode'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.title.factoryQRCode'
            ),"placeholder":"e.g. 10010001"},model:{value:(_vm.form.factory_qr),callback:function ($$v) {_vm.$set(_vm.form, "factory_qr", $$v)},expression:"form.factory_qr"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.title.simICCID'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.title.simICCID'
            ),"placeholder":"e.g 898901757263760"},model:{value:(_vm.form.iccid),callback:function ($$v) {_vm.$set(_vm.form, "iccid", $$v)},expression:"form.iccid"}}),_c('AppInput',{attrs:{"type":"tel","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.title.simPhoneNumber'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.title.simPhoneNumber'
            ),"placeholder":"e.g. 01757263760"},model:{value:(_vm.form.sim_number),callback:function ($$v) {_vm.$set(_vm.form, "sim_number", $$v)},expression:"form.sim_number"}})],1),(_vm.isEditing)?[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.firmwareKey'
              ),"label":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.firmwareKey'
              ),"placeholder":"e.g. yOTmK50"},model:{value:(_vm.form.firmware_key),callback:function ($$v) {_vm.$set(_vm.form, "firmware_key", $$v)},expression:"form.firmware_key"}}),_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.firmwareType'
              ),"label":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.firmwareType'
              ),"placeholder":"e.g. ext2"},model:{value:(_vm.form.firmware_type),callback:function ($$v) {_vm.$set(_vm.form, "firmware_type", $$v)},expression:"form.firmware_type"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.operatorAPN'
              ),"label":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.operatorAPN'
              ),"placeholder":"e.g. yOTmK50"},model:{value:(_vm.form.operator_apn),callback:function ($$v) {_vm.$set(_vm.form, "operator_apn", $$v)},expression:"form.operator_apn"}}),_c('AppInput',{attrs:{"type":"date","name":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.registrationDate'
              ),"label":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.registrationDate'
              ),"placeholder":"dd/mm/yyyy","disabled":""},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}})],1)]:_vm._e(),_c('div',[_c('TCheckbox',{attrs:{"name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.title.addDescriptionOptional'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.iot.title.addDescriptionOptional'
            ),"wrapped":""},on:{"change":_vm.onUseDescriptionField},model:{value:(_vm.useDescriptionField),callback:function ($$v) {_vm.useDescriptionField=$$v},expression:"useDescriptionField"}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.useDescriptionField)?_c('AppInput',{ref:"descriptionFieldRef",attrs:{"type":"textarea","name":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.description'
              ),"label":_vm.$t(
                'components.vehicleManagement.addEdit.steps.iot.title.description'
              ),"rules":"","placeholder":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}):_vm._e()],1)],1)],_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }